const colors = {
  0: "black",
  1: "darkBlue",
  2: "darkGreen",
  3: "darkCyan",
  4: "darkRed",
  5: "purple",
  6: "gold",
  7: "lightGray",
  8: "gray",
  9: "blue",
  a: "greenYellow",
  b: "cyan",
  c: "red",
  d: "magenta",
  e: "yellow",
  f: "white",
  k: "inherit",
  l: "inherit",
  m: "inherit",
  n: "inherit",
  o: "inherit",
  r: "white",
};

export default colors;
